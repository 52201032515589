<template>
    <el-form ref="form" :model="form" label-width="100px">
      <input type="hidden" v-model="form.id" />
      <el-form-item label="产品标题">
        <el-input v-model="form.pro_title"></el-input>
      </el-form-item>
      <el-form-item label="所属栏目">
        <el-select v-model="form.cate_id"  placeholder="请选择">
          <el-option
              v-for="item in cateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="关键词">
        <el-input   v-model="form.pro_key" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="简介">
        <el-input type="textarea" :rows="2"  v-model="form.pro_info" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="路由">
        <el-input v-model="form.pro_route" placeholder="文章链接会根据路由更改"></el-input>
      </el-form-item>
      <el-form-item label="作者">
        <el-input v-model="form.pro_writer"></el-input>
      </el-form-item>
      <el-form-item label="缩略图">
        <el-upload
            class="upload-demo"
            action="/api/Upload/upload.html"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-success="handleAvatarSuccess"
            :file-list="form.fileList"
            list-type="picture"
            :data="{'field':'pro_img'}"
            :limit="1"
            name="image[]"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">
            只能上传jpg/png文件，且不超过2Mb
          </div>
        </el-upload>
        <el-input v-model="form.pro_img" type="hidden"></el-input>
      </el-form-item>
      <el-form-item label="轮播图">
        <el-upload
            class="upload-demo"
            multiple
            action="/api/Upload/upload.html"
            :on-preview="handlePreview1"
            :on-remove="handleRemove1"
            :on-success="handleAvatarSuccess1"
            :file-list="form.fileList1"
            list-type="picture-card"
            :limit=100
            name="image[]"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">
            只能上传jpg/png文件，且不超过2Mb
          </div>
        </el-upload>
        <el-input v-model="form.pro_imglist" type="hidden"></el-input>
      </el-form-item>
      <el-form-item label="分类简介">
        <el-input type="textarea" :rows="4"  v-model="form.pro_cate" placeholder="分类名称|分类信息"></el-input>
      </el-form-item>
      <el-form-item label="正文">
        <div style="border: 1px solid #ccc;">
          <Toolbar
              style="border-bottom: 1px solid #ccc"
              :editor="editor"
              :defaultConfig="toolbarConfig"
              :mode="mode"
          />
          <Editor
              style="height: 500px; overflow-y: hidden;"
              v-model="form.pro_content"
              :defaultConfig="editorConfig"
              :mode="mode"
              @onCreated="onCreated"
          />
        </div>
      </el-form-item>
      <el-form-item label="产品附件">
        <el-upload
            class="upload-demo"
            action="/api/Upload/fileupload.html"
            :on-preview="handlePreview2"
            :on-remove="handleRemove2"
            :on-success="handleAvatarSuccess2"
            :file-list="form.fileList2"
            list-type="text"
            :limit=50
            name="image[]"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">
            文件不超过50Mb
          </div>
        </el-upload>
        <el-input v-model="form.pro_file" type="hidden"></el-input>
      </el-form-item>
      <el-form-item label="产品标签">
        <el-input v-model="form.pro_label" placeholder="标签|标签"></el-input>
      </el-form-item>
      <el-form-item label="关联产品">
        <el-input v-model="form.pro_pro" placeholder="1,2"></el-input>
      </el-form-item>
      <el-form-item label="外部链接">
        <el-input v-model="form.islink"></el-input>
      </el-form-item>
<!--      <el-form-item label="添加时间">-->
<!--        <el-date-picker-->
<!--            value-format="yyyy:MM:dd HH:mm:ss"-->
<!--            v-model="form.release_time"-->
<!--            type="date"-->
<!--            placeholder="选择日期">-->
<!--        </el-date-picker>-->
<!--      </el-form-item>-->
      <el-form-item label="发布时间">
        <el-date-picker
            value-format="yyyy:MM:dd HH:mm:ss"
            v-model="form.release_time"
            type="date"
            placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="排序">
        <el-input-number v-model="form.top" :min="0" :max="100" label=""></el-input-number>
      </el-form-item>
      <el-form-item label="状态">
        <el-radio-group v-model="form.pro_status">
          <el-radio-button label="0">未审核</el-radio-button>
          <el-radio-button label="1">正常</el-radio-button>
          <el-radio-button label="2">推荐</el-radio-button>
          <el-radio-button label="3">暂停</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">保存</el-button>
        <el-button @click="handleClose">重置</el-button>
      </el-form-item>
    </el-form>
</template>

<script>
import axios from "axios";
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'

export default {
  name: "getProductView",
  components: { Editor, Toolbar },
  data(){
    return{

      form: {
        id: "",
        cate_id: "",
        pro_title: "",
        pro_img: "",
        pro_writer: "",
        pro_key: "",
        top: 0,
        release_time: '',
        pro_cate: '',
        pro_label: '',
        pro_pro: '',
        pro_case: '',
        pro_route: "",
        pro_info:'',
        islink: '',
        pro_content: '',
        pro_imglist: '',
        pro_file: '',
        fileList:[],
        fileList1:[],
        fileList2:[],
        // Contentexp:[],
        pro_status: 0
      },
      cateList: [],
      editor: null,
      toolbarConfig: { },
      editorConfig: {
        placeholder: '请输入内容...',
        MENU_CONF:{
          'uploadImage' : {
            // customUpload: this.uploadImg,
            server: '/api/Upload/uploadEditor.html',
            fieldName: 'image[]',
            maxFileSize: 2 * 1024 * 1024, // 2M
            maxNumberOfFiles: 50,
            allowedFileTypes: ['image/*'],
            timeout: 5 * 1000, // 5 秒
          },
          'uploadVideo':{
            // customUpload: this.uploadImg,
            server: '/api/Upload/editorFile.html',
            withCredentials: true,
            fieldName: 'files[]',
            maxFileSize: 200 * 1024 * 1024, // 200M
            // maxNumberOfFiles: 3,
            // allowedFileTypes: ['video/*'],
            // timeout: 15 * 1000, // 5 秒
          }
        }
      },
      mode: 'default', // or 'simple'
    }
  },
  // created() {
  //   this.getCategory();
  // },
  mounted() {
    let geturl = window.location.href;
    let geturlinfo = geturl.split('?')[1];
    let getqys = new URLSearchParams('?'+geturlinfo);
    let id = getqys.get('id');
    console.log(id);
    axios.get('/api/Category/easyList.html').then((res)=>{
      this.cateList = res.data;
    });
    if(id > 0){
      axios.get('/api/Product/getProduct.html?id='+id).then((res)=>{
        if(res.data.code == 200){
          this.form = res.data.data;
        }
      });
    }else{
      // this.form.release_time = Date.now();
    }

  },

  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },
  methods:{
    expurl(url){
      url = url.split('upload/');
      return url[1];
    },
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    resetData(){
      this.form = this.$options.data().form;
    },
    handleClose() {
      this.resetData()
    },
    handleAvatarSuccess(res,f,ff) {
      console.log(f);
      console.log(ff);
      let array = Object.values(res);
      this.form.pro_img = array[0]['file_url'];
    },
    handleChange(value) {
      console.log(value);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleAvatarSuccess1(res) {
      let array = Object.values(res);
      let img = this.form.pro_imglist;
      var imglist = [];
      if(img) imglist = img.split(',');
      imglist.push(array[0]['file_url']);
      this.form.pro_imglist = imglist.join(',');
    },
    handleRemove1(file, fileList) {
      let img = this.form.pro_imglist;
      var imglist = img.split(',');
      var url = this.expurl(file.url);
      for(let i = 0; i < imglist.length; i++){
        var str = imglist[i].replace(/\\/g,'/');
        if(str == url){
          imglist.splice(i,1);
        }
      }
      this.form.pro_imglist = imglist.join(',');
      console.log(fileList);
    },
    handlePreview1(file) {
      console.log(file);
    },
    handleAvatarSuccess2(res) {
      let array = Object.values(res);
      let img = this.form.pro_file;
      var imglist = [];
      if(img) imglist = img.split(',');
      imglist.push(array[0]['file_url']);
      this.form.pro_file = imglist.join(',');
    },
    handleRemove2(file, fileList) {
      let img = this.form.pro_file;
      var imglist = img.split(',');
      var url = this.expurl(file.url);
      for(let i = 0; i < imglist.length; i++){
        if(imglist[i] == url){
          imglist.splice(i,1);
        }
      }
      this.form.pro_file = imglist.join(',');
      console.log(fileList);
    },
    handlePreview2(file) {
      console.log(file);
    },
    onSubmit() {
      axios({
        method:'post',
        url:'/api/Product/setProduct.html',
        data:this.form
      }).then((res)=>{
        if(res.data.code != 200) {
          alert(res.data.msg);
        }
        else{
          alert(res.data.msg);
          // this.$props.id = res.data.id;
          this.form.id = res.data.id;
          // this.$router.go(0);
        }
      })
    },
  }
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>

</style>